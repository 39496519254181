import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import {
  Box,
  Flex,
  Text,
  Heading,
  Link,
  Image,
  chakra,
} from "@chakra-ui/react";
import GatsbyLink from "gatsby-link";
import RelatedSidebar from "../components/RelatedSidebar";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from "next-share";
import { LinkIcon, ArrowBackIcon } from "@chakra-ui/icons";
//import all fonts so hotlinks from newsletter don't load without them
import "@fontsource/montserrat/100.css";
import "@fontsource/montserrat/200.css";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";
import "@fontsource/montserrat/900.css";
import "@fontsource/work-sans/100.css";
import "@fontsource/work-sans/200.css";
import "@fontsource/work-sans/300.css";
import "@fontsource/work-sans/400.css";
import "@fontsource/work-sans/500.css";
import "@fontsource/work-sans/600.css";
import "@fontsource/work-sans/700.css";
import "@fontsource/work-sans/800.css";
import "@fontsource/work-sans/900.css";
import "@fontsource/cardo/400.css";
import "@fontsource/cardo/700.css";
import "@fontsource/square-peg";
const Article = (props) => {
  const location = "";
  const data = props.pageContext.data;
  const id = props.pageContext.id;
  const local_image_path = `/images/${data.category}_${id}.jpg`;
  const related = props.pageContext.related;
  const shareUrl = `${props.location.host}${props.location.pathname}`;
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const copyLink = () => {
    navigator.clipboard.writeText(shareUrl);
    setShowCopiedMessage(true);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCopiedMessage(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [showCopiedMessage]);

  return (
    <Layout path={location.pathname} isArticle={true} bgColor="gray.100">
      <Flex direction={{ base: "column", md: "row" }}>
        <Box>
          <Flex px="8" pt="4" align="center" color="blue">
            <ArrowBackIcon mr="1" mt="2px" />
            <Link
              as={GatsbyLink}
              href="/"
              fontWeight="500"
              mr="1"
              fontSize="16px"
              // textDecoration="underline"
            >
              go back
            </Link>
          </Flex>
          <Flex
            minH="65vh"
            fontFamily="Work Sans"
            direction={{ base: "column", md: "row" }}
            bg="gray.100"
            align={{ base: "center", md: "start" }}
          >
            <Flex
              direction="column"
              w={{ base: "100%", sm: "90vw", md: "65vw", lg: "70vw" }}
              px="8"
              py="4"
              h="100%"
            >
              {/* main section */}

              <Heading
                w="100%"
                color="green.500"
                as="h1"
                mb="1"
                fontFamily="Montserrat"
                fontSize={{ base: "18px", sm: "20px", md: "24px", lg: "28px" }}
                fontWeight="600"
              >
                {data.headline}
              </Heading>
              <Text
                color="green.500"
                fontSize={{ base: "14px", sm: "15px", md: "16px", lg: "18px" }}
                fontWeight="500"
                fontStyle="italic"
              >
                {data.source}
              </Text>
              {/* photo block and summary block */}
              <Flex h="100%" direction={{ base: "column", lg: "row" }}>
                {/* photo */}
                <Flex
                  direction="column"
                  w={{
                    base: "100%",
                    lg: "350px",
                    xl: "450px",
                  }}
                  flexShrink="0"
                  align="start"
                  mr={{ base: "0", md: "4" }}
                >
                  <Flex w="100%" pt="3">
                    <Image
                      objectFit="contain"
                      src={local_image_path}
                      alt={data.image_description}
                      w="100%"
                    />
                  </Flex>
                  <Flex
                    bg="green.300"
                    h="60px"
                    w="100%"
                    justify="flex-end"
                    px="4"
                    pos="relative"
                  >
                    <FacebookShareButton
                      url={shareUrl}
                      quote={
                        "next-share is a social share buttons for your next React apps."
                      }
                      hashtag={"#nextshare"}
                    >
                      <FacebookIcon
                        size={40}
                        round
                        bgStyle={{ fill: "#85c6bf", color: "white" }}
                      />
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={shareUrl}
                      quote={
                        "next-share is a social share buttons for your next React apps."
                      }
                      hashtag={"#nextshare"}
                    >
                      <TwitterIcon
                        size={40}
                        round
                        bgStyle={{ fill: "#85c6bf", color: "white" }}
                      />
                    </TwitterShareButton>
                    <LinkedinShareButton
                      url={shareUrl}
                      quote={
                        "next-share is a social share buttons for your next React apps."
                      }
                      hashtag={"#nextshare"}
                    >
                      <LinkedinIcon
                        size={40}
                        round
                        bgStyle={{ fill: "#85c6bf", color: "white" }}
                      />
                    </LinkedinShareButton>
                    <EmailShareButton
                      url={shareUrl}
                      quote={
                        "next-share is a social share buttons for your next React apps."
                      }
                      hashtag={"#nextshare"}
                    >
                      <EmailIcon
                        size={40}
                        transparent
                        round
                        bgStyle={{ fill: "#85c6bf", color: "white" }}
                      />
                    </EmailShareButton>
                    <Flex
                      as="button"
                      align="center"
                      px="2"
                      onClick={() => copyLink()}
                    >
                      <LinkIcon color="white" h="18px" w="18px" mt="-1px" />
                    </Flex>
                    {showCopiedMessage && (
                      <Flex
                        opacity=".9"
                        bg="orange"
                        pos="absolute"
                        top="-4"
                        right="0"
                        px="3"
                        color="white"
                        fontWeight="600"
                      >
                        link copied!
                      </Flex>
                    )}
                  </Flex>
                </Flex>
                {/* summary */}
                <Flex
                  direction="column"
                  w={{
                    base: "100%",
                    lg: "40vw",
                    xl: "500px",
                  }}
                >
                  <Text
                    whiteSpace="pre-line"
                    fontSize={{
                      base: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "16px",
                    }}
                    fontFamily="Montserrat"
                    fontWeight="600"
                    color="green.500"
                    px="2"
                    pt={{ base: "4", md: "2" }}
                  >
                    {data.summary}
                  </Text>

                  <Link
                    target="_blank"
                    href={data.link}
                    fontSize={{
                      base: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "16px",
                    }}
                    fontWeight="500"
                    color="yellow.600"
                    p="2"
                  >
                    {data.category !== "books" &&
                      data.category !== "videos" && (
                        <>
                          See full story at
                          <chakra.span fontStyle="italic">
                            {" " + data.source}
                          </chakra.span>
                        </>
                      )}
                    {data.category === "videos" && (
                      <>
                        Watch video from
                        <chakra.span fontStyle="italic">
                          {" " + data.source}
                        </chakra.span>
                      </>
                    )}
                    {data.category === "books" && (
                      <>
                        See more at
                        <chakra.span fontStyle="italic">
                          {" AlzAuthors"}
                        </chakra.span>
                      </>
                    )}
                  </Link>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Box>
        {/* sidebar */}
        <Flex
          bg="gray.200"
          w={{ base: "100%", md: "35vw", lg: "30vw" }}
          py="8"
          px="4"
          minH={{ base: "100%", md: "100vh", lg: "85vh", xl: "70vh" }}
        >
          <RelatedSidebar items={related} category={data.category} />
        </Flex>
      </Flex>
    </Layout>
  );
};

export default Article;
