import React from "react";
import { Flex, Image, Link, Text } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";
import slugify from "slugify";

export default function RelatedSidebar({ items, category }) {
  return (
    <Flex
      fontFamily="Montserrat"
      py="8"
      px={{ base: "12", md: "4" }}
      direction="column"
    >
      <Flex direction="column" align={{ base: "start", md: "center" }}>
        <Text mr="1" color="gray.500" fontWeight="500" fontSize="16px">
          {"More Stories From"}
        </Text>
        <Text color="green.500" fontWeight="600" fontSize="16px">
          {category.toUpperCase()}
        </Text>
      </Flex>
      <Flex direction="column" py="3">
        {items.map((item) => {
          // const img = getImage(item.image.localFiles[0]);
          const local_image_path = `/images/${item.category}_${item.id}.jpg`;

          const slug = slugify(item.headline, {
            lower: true,
            strict: true,
          });
          const url = `/${item.category}/${slug}`;
          return (
            <Flex as={GatsbyLink} to={url} py="3">
              <Flex>
                <Flex h="80px" w="80px" flexShrink="0">
                  <Image
                    src={local_image_path}
                    alt={item.image_description}
                    width="100%"
                    objectFit="cover"
                  />
                </Flex>
                <Flex direction="column">
                  <Text color="gray.500" fontWeight="600" px="2">
                    {item.headline}
                  </Text>
                  <Text
                    color="gray.500"
                    fontWeight="500"
                    px="2"
                    fontStyle="italic"
                  >
                    {item.source}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
      <Flex justify="center">
        <Link
          as={GatsbyLink}
          color="green.500"
          fontWeight="600"
          fontSize="16px"
          to={`/${category}`}
        >
          {`MORE ${category.toUpperCase()}`}{" "}
        </Link>
      </Flex>
    </Flex>
  );
}
